import {post} from './request'
//登录
export function LoginByPassword(params){
  return post('NewApi/Account/LoginByPassword',params)
}
//登出
export function Logout(params){
  return post('NewApi/Account/Logout',params)
}
//编辑赛季复审
export function EditSeasonReviewApply(params){
  return post('NewApi/LeagueOwner/EditSeasonReviewApply',params)
}

//保存赛季复审
export function SaveSeasonReviewApply(params){
  return post('NewApi/LeagueOwner/SaveSeasonReviewApply',params)
}
//提交赛季复审
export function SubmitSeasonReviewApply(params){
  return post('NewApi/LeagueOwner/SubmitSeasonReviewApply',params)
}

//创建新赛季
export function CreateMyNewSeason(params){
  return post('NewApi/LeagueOwner/CreateMyNewSeason',params)
}

//创建历史赛季
export function AddMyHistorySeason(params){
  return post('NewApi/LeagueOwner/AddMyHistorySeason',params)
}

//获取赛季管理列表
export function GetMySeasonReviewList(params){
  return post('NewApi/LeagueOwner/GetMySeasonReviewList',params)
}

//获取我的主赛事
export function GetMyMainLeague(params){
  return post('NewApi/LeagueOwner/GetMyMainLeague',params)
}

//更新我的主赛事
export function UpdateMyMainLeague(params){
  return post('NewApi/LeagueOwner/UpdateMyMainLeague',params)
}

//根据id获取赛季
export function GetMySeason(params){
  return post('NewApi/LeagueOwner/GetMySeason',params)
}

//更新新赛季
export function UpdateMyNewSeason(params){
  return post('NewApi/LeagueOwner/UpdateMyNewSeason',params)
}

//更新历史赛季
export function UpdateMyHistorySeason(params){
  return post('NewApi/LeagueOwner/UpdateMyHistorySeason',params)
}

//获取子赛事可选的运动项目列表
export function GetEventsListForNewSubLeague(params){
  return post('NewApi/LeagueOwner/GetEventsListForNewSubLeague',params)
}
GetEditSubleagueInitInfo
//获取可添加子赛事的赛季列表
export function GetSeasonListForNewSubLeague(params){
  return post('NewApi/LeagueOwner/GetSeasonListForNewSubLeague',params)
}

//获取新赛事创建数据
export function GetMyNewSessionCreation(params){
  return post('NewApi/LeagueOwner/GetMyNewSessionCreation',params)
}

// 获取新建子赛事的文件上传配置
export function GetUploadOptionsForAddSubLeague(params){
  return post('NewApi/LeagueOwner/GetUploadOptionsForAddSubLeague',params)
}
//获取编辑子赛事的文件上传配置
export function GetUploadOptionsForEditSubLeague(params){
  return post('NewApi/LeagueOwner/GetUploadOptionsForEditSubLeague',params)
}
//获取子赛事列表
export function GetMySubleagueList(params){
  return post('NewApi/LeagueOwner/GetMySubleagueList',params)
}
//查询是否有赛事
export function CheckHaveSubleague(params){
  return post('NewApi/LeagueOwner/CheckHaveSubleague',params)
}
//获取添加修改子赛事信息
export function GetEditSubleagueInitInfo(params){
  return post('NewApi/LeagueOwner/GetEditSubleagueInitInfo',params)
}
//获取子赛事详情
export function GetSubleagueDetail(params){
  return post('NewApi/LeagueOwner/GetSubleagueDetail',params)
}
//创建子赛事
export function CreateSubleague(params){
  return post('NewApi/LeagueOwner/CreateSubleague',params)
}
//修改子赛事
export function EditSubleague(params){
  return post('NewApi/LeagueOwner/EditSubleague',params)
}
//比赛群设置
export function SaveSubleagueMatchGroupSetting(params){
  return post('/NewApi/LeagueOwner/SaveSubleagueMatchGroupSetting',params)
}


//获取子赛事分组信息
export function GetLeagueEventsGroup(params){
  return post('NewApi/LeagueOwner/GetLeagueEventsGroup',params)
}
//获取报名设置列表
export function GetMyLeagueEnrollList(params){
  return post('NewApi/LeagueOwner/GetMyLeagueEnrollList',params)
}
//获取创建报名的初始信息
export function GetCreateLeagueEnrollInitInfo(params){
  return post('NewApi/LeagueOwner/GetCreateLeagueEnrollInitInfo',params)
}
//获取运动项目报名角色信息
export function GetTeamUserRoleList(params){
  return post('NewApi/LeagueOwner/GetTeamUserRoleList',params)
}
//获取报名设置详情
export function GetLeagueEnrollDetail(params){
  return post('NewApi/LeagueOwner/GetLeagueEnrollDetail',params)
}
//创建报名
export function CreateLeagueEnroll(params){
  return post('NewApi/LeagueOwner/CreateLeagueEnroll',params)
}
//修改报名
export function EditLeagueEnroll(params){
  return post('NewApi/LeagueOwner/EditLeagueEnroll',params)
}
//修改报名时间
export function EditLeagueEnrollTime(params){
  return post('NewApi/LeagueOwner/EditLeagueEnrollTime',params)
}
//报名发布
export function PublishLeagueEnroll(params){
  return post('NewApi/LeagueOwner/PublishLeagueEnroll',params)
}
//取消报名
export function CancelLeagueEnroll(params){
  return post('NewApi/LeagueOwner/CancelLeagueEnroll',params)
}
//删除报名
export function DelLeagueEnroll(params){
  return post('NewApi/LeagueOwner/DelLeagueEnroll',params)
}
//获取赛事的上次报名设置
export function GetLastLeagueEnrollDetail(params){
  return post('NewApi/LeagueOwner/GetLastLeagueEnrollDetail',params)
}
//获取报名审核列表
export function GetLeagueEnrollAuditList(params){
  return post('NewApi/LeagueOwner/GetLeagueEnrollAuditList',params)
}
//开始报名公示
export function StartLeagueEnrollPublic(params){
  return post('NewApi/LeagueOwner/StartLeagueEnrollPublic',params)
}
//修改报名公示的结束时间
export function UpdateLeagueEnrollPublicTime(params){
  return post('NewApi/LeagueOwner/UpdateLeagueEnrollPublicTime',params)
}
//结束报名公示
export function EndLeagueEnrollPublic(params){
  return post('NewApi/LeagueOwner/EndLeagueEnrollPublic',params)
}
//修改信息修改的结束时间
export function OpenLeagueEnrollEditTime(params){
  return post('NewApi/LeagueOwner/OpenLeagueEnrollEditTime',params)
}
//填写线下报名数量
export function SaveLeagueOfflineEnrollData(params){
  return post('NewApi/LeagueOwner/SaveLeagueOfflineEnrollData',params)
}
//完成赛事报名
export function CompleteLeagueEnroll(params){
  return post('NewApi/LeagueOwner/CompleteLeagueEnroll',params)
}
//获取赛事报名数据
export function GetLeagueEnrollDataAuditListList(params){
  return post('NewApi/LeagueOwner/GetLeagueEnrollDataAuditListList',params)
}
//获取队伍/个人报名详情
export function GetLeagueEnrollTeamDetail(params){
  return post('NewApi/LeagueOwner/GetLeagueEnrollTeamDetail',params,{code:[3893,3894]})
}
//报名参赛审核通过
export function ApproveLeagueEnrollTeam(params){
  return post('NewApi/LeagueOwner/ApproveLeagueEnrollTeam',params,{code:[3893,3894]})
}
//报名参赛审核不通过
export function RejectLeagueEnrollTeam(params){
  return post('NewApi/LeagueOwner/RejectLeagueEnrollTeam',params,{code:[3893,3894]})
}
//获取报名记录单条数据
export function GetOneLeagueEnrollDataAuditInfo(params){
  return post('NewApi/LeagueOwner/GetOneLeagueEnrollDataAuditInfo',params)
}
//举报参赛运动员
export function ImpeachEnrollTeamMember(params){
  return post('NewApi/LeagueOwner/ImpeachEnrollTeamMember',params,{code:[3893,3894]})
}
//撤销举报参赛运动员
export function CancelImpeachEnrollTeamMember(params){
  return post('NewApi/LeagueOwner/CancelImpeachEnrollTeamMember',params,{code:[3893,3894]})
}
//获取赛事报名公示设置
export function GetLeagueEnrollPublicSetting(params){
  return post('NewApi/LeagueOwner/GetLeagueEnrollPublicSetting',params)
}
//导出报名参赛者名单
export function ExportLeagueEnrollTeamNameList(params){
  return post('NewApi/LeagueOwner/ExportLeagueEnrollTeamNameList',params)
}
//获取发送报名通知短信的结果
export function SendLeaguEnrollResultNotice(params){
  return post('NewApi/LeagueOwner/SendLeaguEnrollResultNotice',params)
}
//解析导入的赛事报名名单
export function ParseImportLeagueEnrollNameList(params){
  return post('NewApi/LeagueOwner/ParseImportLeagueEnrollNameList',params)
}
//导入赛事报名名单​
export function ImportLeagueEnrollNameList(params){
  return post('NewApi/LeagueOwner/ImportLeagueEnrollNameList',params)
}
//导出报名数据（上传报名册页面）
export function ExportLeagueEnrollNameList(params){
  return post('NewApi/LeagueOwner/ExportLeagueEnrollNameList',params)
}
//获取赛事成绩列表
export function GetScoreOrderList(params){
  return post('NewApi/LeagueOwner/GetScoreOrderList',params)
}
//导出成绩
export function ExportScoreOrderNameList(params){
  return post('NewApi/LeagueOwner/ExportScoreOrderNameList',params)
}
//修改赛事成绩
export function AlterScoreOrder(params){
  return post('NewApi/LeagueOwner/AlterScoreOrder',params)
}
//删除赛事成绩
export function DelScoreOrders(params){
  return post('NewApi/LeagueOwner/DelScoreOrders',params)
}
//获取上传电子证书的配置
export function GetScoreOrderUploadOptions(params){
  return post('NewApi/LeagueOwner/GetScoreOrderUploadOptions',params)
}
//上传/删除赛事成绩电子证书
export function SaveScoreOrderCertificateFile(params){
  return post('NewApi/LeagueOwner/SaveScoreOrderCertificateFile',params)
}
//获取参赛者信息录入成绩
export function GetEnrollTeamsForScoreOrderInput(params){
  return post('NewApi/LeagueOwner/GetEnrollTeamsForScoreOrderInput',params)
}
//保存录入的成绩信息
export function SaveInputScoreOrder(params){
  return post('NewApi/LeagueOwner/SaveInputScoreOrder',params)
}
//解析导入的成绩册
export function ParseImportScoreOrderNameList(params){
  return post('NewApi/LeagueOwner/ParseImportScoreOrderNameList',params)
}
//保存导入的成绩册
export function SaveImportScoreOrderNameList(params){
  return post('NewApi/LeagueOwner/SaveImportScoreOrderNameList',params)
}
//导出成绩数据（上传成绩册页面）
export function ExportScoreOrderTplNameList(params){
  return post('NewApi/LeagueOwner/ExportScoreOrderTplNameList',params)
}
//获取主赛事大师分体系信息
export function GetMainLeagueMastersInfo(params){
  return post('NewApi/LeagueOwner/GetMainLeagueMastersInfo',params)
}
