import axios from 'axios'
import store from '@/store'
import Router from '@/router'
import {
  Message
} from 'element-ui'
const ErrorLoginArr = [9611,9626,9651];
// 创建axios实例
const service = axios.create({
  timeout: 15000, // 请求超时时间
  headers:{
    "Content-Type":"application/json; charset=utf-8",
    "Accept-Language":"zh-CN"
  },
  withCredentials:true
})
// request拦截器
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (!config.data) { // 没有参数时，config.data为null，需要转下类型
        config.data = {};
      }
      config.data = JSON.stringify(config.data); 
    }
    return config
  },
  error => {
    Message.error("服务器异常");
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if (response.status !== 200) {
        Message.error("服务器异常");
        return Promise.reject(response.data);
    } else {
        return response;
    }
  },
  err => {
    var response = err.response;
    Message.error("服务器异常");
    return Promise.reject(response);
  }
)
//post
export function post(url,params = {},option = {}){
  return new Promise((resolve, reject)=>{
      try{
        if(store.state.leagueInfo.id){
          params.MainLeagueId = store.state.leagueInfo.id;
        }
      }catch{}
      service.post(url,params).then(response=>{
        var data = response.data;
        if(data.Code != 0){
          if(!option.code || option.code.length == 0 || option.code.indexOf(data.Code) == -1){
            Message.error(data.Message || ("服务器异常,error:" + data.Code));
          }
          if(ErrorLoginArr.indexOf(data.Code) > -1){
            Router.push('/Login');
          }
        }
        resolve(data);
      }).catch(err=>{
        reject(err);
      })
    })
}
 
