import {post} from '@/api/request'

//获取赛事报名人员肖像照片
export function GetLeagueEnrollPortraitPhotoList(params){
    return post('NewApi/LeagueOwner/GetLeagueEnrollPortraitPhotoList',params)
}
//获取报名字段设置
export function GetLeagueEnrollFieldsOptions(params){
    return post('/NewApi/LeagueOwner/GetLeagueEnrollFieldsOptions',params)
}
